/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Dyf
 * @LastEditTime: 2023-03-06 14:50:53
 * @Descripttion: 资源库模块接口
 */
import HTTP from "@utils/http";
import { PAGE_SIZE } from "@config";

const PREFIX = "/school/schcourseware/"; // 资源库
const PREFIX2 = "/school/schpaper/"; // 试卷库
const PREFIX3 = "/school/schquestionscschid/"; // 题库
const teacher = "/teacher/teaquestion/"; // 题库中心
const PREFIX4 = "/school/stuquestionscschid/"; // 错题集
const PREFIX5 = "/school/teaopenclass/"; // 公开课

/*******
 * @Descripttion: 课件列表
 * @Author: Dyf
 * @param {number} type 列表类型  1. 学校  2. 平台  3. 公共
 * @param {object} params
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $getCourseware(type, params, pagesize = PAGE_SIZE) {
  let url = "";
  switch (Number(type)) {
    case 1:
      url = `${PREFIX}list`;
      break;
    case 2:
      url = `${PREFIX}platform_list`;
      break;
    case 3:
      url = `${PREFIX}public_list`;
      break;
    default:
      break;
  }
  try {
    return await HTTP.post(
      url,
      { ...params, pagesize },
      {
        load: true,
        loadEl: ".courseware-data",
      }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 共享课件
 * @Author: Dyf
 * @param {number} sccou_id 课件id
 * @return {*}
 */
export async function $shareCourseware(sccou_id) {
  try {
    return await HTTP.post(`${PREFIX}share`, { sccou_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 课件详情
 * @Author: Dyf
 * @param {number} type 列表类型 1. 学校  2. 平台
 * @param {number} id 课件id
 * @return {*}
 */
export async function $coursewareDetail(type, id) {
  let params = {};
  let url = "";
  switch (Number(type)) {
    case 1:
      params.sccou_id = id;
      url = `${PREFIX}info`;
      break;
    case 2:
      params.plcou_id = id;
      url = `${PREFIX}platform_info`;
      break;
  }
  try {
    return await HTTP.post(url, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 公共课件访问量增加
 * @Author: Dyf
 * @param {number} plcou_pub_id 课件id
 * @return {*}
 */
export async function $publicCoursewareAddView(plcou_pub_id) {
  try {
    return await HTTP.post(`${PREFIX}public_add_num`, { plcou_pub_id });
  } catch (error) {
    return error;
  }
}

/* 试题库 - 试卷 */
/*******
 * @Descripttion: 试卷列表
 * @Author: Dyf
 * @param {number} type 试卷类型 1. 校本  2. 平台
 * @param {object} params
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $exampaperList(type, params, pagesize = PAGE_SIZE) {
  let prefix = "";
  switch (Number(type)) {
    case 1:
      prefix = "sch";
      break;
    case 2:
      prefix = "pla";
      break;
    default:
      break;
  }
  let url = `/school/${prefix}paper/list`;
  try {
    return await HTTP.post(url, { ...params, pagesize });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 试卷详情
 * @Author: Dyf
 * @param {number} type 试卷类型 1. 校本  2. 平台
 * @param {number} id 试卷id
 * @return {*}
 */
export async function $exampaperDetail(type, id) {
  try {
    let prefix = "";
    let params = {};
    switch (Number(type)) {
      case 1:
        prefix = "sch";
        params.scpap_id = id;
        break;
      case 2:
        prefix = "pla";
        params.plpap_id = id;
        break;
      default:
        break;
    }
    let url = `/school/${prefix}paper/info`;
    return await HTTP.post(url, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 加入学校试卷
 * @Author: Dyf
 * @param {number} plpap_id 试卷id
 * @return {*}
 */
export async function $exampaperInsert(plpap_id) {
  try {
    return await HTTP.post("/school/plapaper/insert_my", { plpap_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 共享试卷
 * @Author: Dyf
 * @param {number} scpap_id 试卷id
 * @return {*}
 */
export async function $shareExampaper(scpap_id) {
  try {
    return await HTTP.post(`${PREFIX2}share_paper`, { scpap_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 新增试卷
 * @Author: Dyf
 * @param {string} params
 * @return {*}
 */
 export async function $addExampaper(params) {
  try {
    return await HTTP.post(`${PREFIX2}add`, params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: word批量添加题目
 * @Author: Dyf
 * @param {string} params
 * @return {*}
 */
export async function $teaquestionAdds(params) {
  try {
    return await HTTP.post(`${PREFIX3}adds`, params);
  } catch (error) {
    return error;
  }
}



/*******
 *公式识别
 * @return {*}
 */
 export async function $recognizeeduformula(params) {
  try {
    return await HTTP.post(`${teacher}recognizeeduformula`, params);
  } catch (error) {
    return error;
  }
}
/*******
 *精细化排版
 * subject	数学
 * @return {*}
 */
export async function $recognizeedupaperstructed(params) {
  try {
    return await HTTP.post(`${teacher}recognizeedupaperstructed`, params);
  } catch (error) {
    return error;
  }
}

/*******
 *切题识别
 * @return {*}
 */
 export async function $recognizeedupapercut(params) {
  try {
    return await HTTP.post(`${teacher}recognizeedupapercut`, params);
  } catch (error) {
    return error;
  }
}
/*******
 *切题识别
 * @return {*}
 */
 export async function $ocradd(params) {
  try {
    return await HTTP.post(`${teacher}ocradd`, params);
  } catch (error) {
    return error;
  }
}

/*******
 *试卷识别
 * @return {*}
 */
 export async function $recognizeedupaperocr(params) {
  try {
    return await HTTP.post(`${teacher}recognizeedupaperocr`, params);
  } catch (error) {
    return error;
  }
}

/*******
 *题目识别
 * @return {*}
 */
 export async function $recognizeeduquestionocr(params) {
  try {
    return await HTTP.post(`${teacher}recognizeeduquestionocr`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑试卷
 * @Author: Dyf
 * @param {string} params
 * @return {*}
 */
export async function $editExampaper(params) {
  try {
    return await HTTP.post(`${PREFIX2}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除试卷
 * @Author: Dyf
 * @param {number} scpap_id 试卷id
 * @return {*}
 */
export async function $delExampaper(scpap_id) {
  try {
    return await HTTP.post(`${PREFIX2}delete_paper`, { scpap_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 手动选题
 * @Author: Dyf
 * @param {number} params.syque_typ_id 题型id
 * @param {string} params.teque_title 题目标题
 * @param {number} params.pageindex 当前页
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $manualTopic(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(`${PREFIX2}hand_move`, { ...params, pagesize });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 随机选题
 * @Author: Dyf
 * @param {number} params.syque_typ_id 题型id
 * @param {number} params.syque_typ_num 题型数量
 * @param {number} params.syque_typ_score 每题分数
 * @return {*}
 */
export async function $randomTopic(params) {
  try {
    return await HTTP.post(`${PREFIX2}random_move`, params);
  } catch (error) {
    return error;
  }
}

/* 试题库 - 题库 */
/*******
 * @Descripttion: 题库列表
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页展示条数
 * @return {*}
 */
export async function $getQuesGroup(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(`${PREFIX3}school_questionbank`, {
      ...params,
      pagesize,
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取题目列表
 * @Author: Dyf
 * @param {number} type 题目列表类型 1.学校  2.平台
 * @param {object} params 筛选参数
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $getQuesList(type, params, pagesize = PAGE_SIZE) {
  let url = "";
  switch (Number(type)) {
    case 1:
      url = `${PREFIX3}list`;
      break;
    case 2:
      url = "/school/plaquestion/list";
      break;
    default:
      break;
  }
  try {
    return await HTTP.get(url, { ...params, pagesize });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 章节数据(筛选)
 * @Author: Dyf
 * @param {number} params.sccou_id 题库id(教材版本id)
 * @param {number} params.sccou_cha_pid 章id
 * @param {string} params.chapter_word 关键字
 * @return {*}
 */
export async function $getQuesChapter(params) {
  try {
    return await HTTP.post(`${PREFIX3}courses_chapter`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 题目详情
 * @Author: Dyf
 * @param {number} scque_id 题目id
 * @return {*}
 */
export async function $quesDetail(scque_id) {
  try {
    return await HTTP.post(`${PREFIX3}info`, { scque_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 添加题目
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $addQues(params) {
  try {
    return await HTTP.post(`${PREFIX3}add`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 编辑题目
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $editQues(params) {
  try {
    return await HTTP.post(`${PREFIX3}edit`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除题目
 * @Author: Dyf
 * @param {number} scque_id 题目id
 * @return {*}
 */
export async function $delQues(scque_id) {
  try {
    return await HTTP.post(`${PREFIX3}delete`, { scque_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 下载模板
 * @Author: Dyf
 * @return {*}
 */
export async function $downloadTemplate(params) {
  try {
    return await HTTP.post(`${PREFIX3}importtemplate`,params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 导入表格
 * @Author: Dyf
 * @param {object} params
 * @return {*}
 */
export async function $importQues(params) {
  try {
    return await HTTP.post(`${PREFIX3}import`, params);
  } catch (error) {
    return error;
  }
}

/* 错题集 */

/*******
 * @Descripttion: 获取错题集数据
 * @Author: Dyf
 * @param {number} params.sccla_grade 年级
 * @param {number} params.sccla_id 班级
 * @return {*}
 */
export async function $wrongQuesInfo(params) {
  try {
    return await HTTP.post(`${PREFIX4}home`, params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取错题集（错题列表）
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $wrongQues(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(`${PREFIX4}list`, { ...params, pagesize });
  } catch (error) {
    return error;
  }
}

/* 公开课 */

/*******
 * @Descripttion: 获取公开课列表
 * @Author: Dyf
 * @param {object} params
 * @param {number} pagesize
 * @return {*}
 */
export async function $getPublicClass(params, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.post(
      `${PREFIX5}list`,
      { ...params, pagesize },
      {
        load: true,
        loadEl: ".public-class-list",
      }
    );
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 公开课详情
 * @Author: Dyf
 * @param {number} teope_id 公开课id
 * @return {*}
 */
export async function $publicClassDetail(teope_id) {
  try {
    return await HTTP.post(`${PREFIX5}info`, { teope_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 公开课设置校内严选
 * @Author: Dyf
 * @param {number} teope_id 公开课id
 * @return {*}
 */
export async function $strictCourses(teope_id) {
  try {
    return await HTTP.post("/school/plastrictcurriculum/teacher_curriculum", {
      teope_id,
    });
  } catch (error) {
    return error;
  }
}
